import React from "react";
import {ValidateField} from "../../formBuilder";
import DatePikcerInput from "../inputs/datepickerinput";

export const DatepickerDecider = (fieldObject) => {
    const {type, name} = fieldObject;
    switch (type) {
        case 'date':
            return (
                <React.Fragment key={name}>
                    <ValidateField {...fieldObject} component={<RenderDatePicker/>}/>
                </React.Fragment>);
        default: return false
    }
};


const RenderDatePicker = (props) => {
    return (<DatePikcerInput {...props}/>)
};
