import {put, takeLatest, call} from "redux-saga/effects";
import {ACTION_TYPES, REDUCER_TYPE} from "./reducer";
import axios from "axios";
import {removeToken, setToken} from "../../config/localStorageUtiles";

const postCall = (url, data) => axios.post(url, data).then(response => response);
const getCall = (url, data) => axios.get(url, data).then(response => response);

const BASE_URL = "/api/auth";

export function* postLogin({data}) {
    try{
        const payload = yield call(postCall, `${BASE_URL}/login`, data);
        yield put({type: ACTION_TYPES[REDUCER_TYPE.LOGIN].SUCCESS, payload});
        yield call(setToken, payload.data.token)
    }catch (error) {
        yield put({type: ACTION_TYPES[REDUCER_TYPE.LOGIN].FAILED, error});
        yield call(removeToken)
    }
}

export function* authCheck({data}) {
    try{
        const payload = yield call(getCall, `${BASE_URL}/authCheck`);
        yield put({type: ACTION_TYPES[REDUCER_TYPE.AUTH_CHECK].SUCCESS, payload});
    }catch (error) {
        yield put({type: ACTION_TYPES[REDUCER_TYPE.AUTH_CHECK].FAILED, error});
        yield call(removeToken)
    }
}

export function* authSaga() {
    yield takeLatest(ACTION_TYPES[REDUCER_TYPE.LOGIN].FETCH, postLogin)
    yield takeLatest(ACTION_TYPES[REDUCER_TYPE.AUTH_CHECK].FETCH, authCheck)
}
