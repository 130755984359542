import axios from "axios";
import {getToken, removeToken} from "./localStorageUtiles";
import {setSnackbarMsg} from "../redux/ui/action";

export const getUrl = () => {
    //const isDEV = process.env.NODE_ENV === 'development';
    const {origin} = window.location;
    //return origin + `${!isDEV ? ":8080" : ""}` + pathname;
    return origin;
};

const axiosInterceptor = (store) => {
    const onRequestSuccess = async config => {
        config.withCredentials = true;
        config.baseURL = `${getUrl()}`;
        if (getToken()) {
            config.headers.authorization = `${getToken()}`;
        }
        return config;
    };

    const onResponseSuccess = (response, request) => {
        const status = response.status;

        if (status === 201) {
            if(response.data && response.data.toast) {
                store.dispatch(setSnackbarMsg({
                    options: {
                        variant: 'success',
                        autoHideDuration: 7000,
                    },
                    message: response.data.toast.title
                }))
            }
        }

        return response;
    };

    const onResponseError = (err) => {
        let status;

        if (err.response || err.status) {
            status = err.status || (err.response.status);
            errorCases(status)
        }
        if (err.response && err.response.data) {
            if (err.response.data.toast) {
                store.dispatch(setSnackbarMsg({
                    options: {
                        variant: 'error',
                        autoHideDuration: 7000,
                        persist: Boolean(err.response.data.toast.persist)
                    },
                    message: err.response.data.toast.title
                }))
            }
        }
        return Promise.reject(err);
    };

    const errorCases = (status) => {
        switch (status) {
            case (400): {
                console.info("400");
                break;
            }
            case (401): {
                console.info("Not authenticated");
                removeToken()
                break;
            }
            default:
                return status
        }
    };

    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);

};

export default axiosInterceptor;
