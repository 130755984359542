import makeStyles from "@material-ui/core/styles/makeStyles";
import {COLORS} from "../../index";

export const useFieldArrayInputTheme = makeStyles((theme) => ({
    root: {
      paddingRight: 0
    },
    addButton: {
        backgroundColor: COLORS.SAVE_BUTTON,
        color: COLORS.MOBILE_NAV_ADMIN,
        '&:hover': {
            backgroundColor: COLORS.SAVE_BUTTON_HOVER
        }
    },
    dragContainer: {
        backgroundColor: COLORS.MOBILE_NAV_ADMIN,
        color: COLORS.MAIN_TITLE_GOLD,
        borderRadius: 6,
        height: 35,
        paddingLeft: 10,
        marginBottom: 5,
        cursor: 'pointer',
        position: 'relative'
    },
    mainPanel: {
        height: 40,
        fontWeight: "bolder",
        backgroundColor: COLORS.MAIN_TITLE_GOLD,
        color: COLORS.MOBILE_NAV_ADMIN,
    },
    dragIcon: {
      cursor: 'grab'
    },
    dragging: {
        cursor: 'grabbing'
    },
    fieldArrayPanel: {
        width: '100%',
        padding: 6,
        marginBottom: 10,
    },
    delIcon: {
        position: 'absolute',
        right: 15,
        bottom: 7,
        color: COLORS.DELETE_BUTTON
    },
    mainPaneldelIcon: {
        bottom: 10,
    },
    accordionRoot: {
        boxShadow: 'none',
        border: 'none'
    }
}));
