import {call, put, takeLatest} from "redux-saga/effects";
import {ACTION_TYPES, REDUCER_TYPE} from "./reducer";
import axios from "axios";
import {removeToken} from "../../config/localStorageUtiles";

const postCall = (url, data) => axios.post(url, data).then(response => response);
const getCall = (url, data) => axios.get(url, data).then(response => response);

const BASE_URL = "/api/users";

export function* getAllUsers() {
    try{
        const payload = yield call(getCall, `${BASE_URL}/`);
        yield put({type: ACTION_TYPES[REDUCER_TYPE.GET_ALL_USERS].SUCCESS, payload});
    }catch (error) {
        yield put({type: ACTION_TYPES[REDUCER_TYPE.GET_ALL_USERS].FAILED, error});
    }
}

export function* saveUsers({data}) {
    try{
        const payload = yield call(postCall, `${BASE_URL}/save`, data);
        yield put({type: ACTION_TYPES[REDUCER_TYPE.SAVE_USERS].SUCCESS, payload});
        yield call(getAllUsers);
    }catch (error) {
        yield put({type: ACTION_TYPES[REDUCER_TYPE.SAVE_USERS].FAILED, error});
    }
}

export function* getProfile({data}) {
    try{
        const payload = yield call(getCall, `${BASE_URL}/getProfile`);
        yield put({type: ACTION_TYPES[REDUCER_TYPE.PROFILE].SUCCESS, payload});
    }catch (error) {
        yield put({type: ACTION_TYPES[REDUCER_TYPE.PROFILE].FAILED, error});
        yield call(removeToken)
    }
}

export function* usersSaga() {
    yield takeLatest(ACTION_TYPES[REDUCER_TYPE.GET_ALL_USERS].FETCH, getAllUsers);
    yield takeLatest(ACTION_TYPES[REDUCER_TYPE.SAVE_USERS].FETCH, saveUsers);
    yield takeLatest(ACTION_TYPES[REDUCER_TYPE.PROFILE].FETCH, getProfile);
}
