const googleFonts = [
    {
        font: 'Roboto',
        weights: [400, '400i'],
    },
    {
        font: 'Roboto Mono',
        weights: [400, 700],
    }, {
        font: 'Muli',
        weights: [300, 400, 500, 700, 800, 900],
    },{
        font: 'Sulphur Point',
        weights: [400, 500, 700, 900],
    },
];

export default googleFonts
