import React from "react";
import Divider from "@material-ui/core/Divider";

export const StyleDecider = (fieldObject) => {
    const {type, tag, name} = fieldObject;
    switch (type) {
        case 'style':
            return (
                <React.Fragment key={name}>
                    <RenderStyle tag={tag} name={name}/>
                </React.Fragment>);
        default:
            return false
    }
};


const RenderStyle = ({tag, name}) => {
    if (tag === 'br') {
        return <br id={name}/>
    } else if (tag === 'hr') {
        return <Divider id={name}/>
    }
};
