import {actionType} from "../utils/actionTypes";

const REDUCER_NAME = "auth";
export const REDUCER_TYPE = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    AUTH_CHECK: "AUTH_CHECK"
};

export const ACTION_TYPES = {
    ...actionType(REDUCER_NAME, REDUCER_TYPE.LOGIN),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.LOGOUT, true),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.AUTH_CHECK)
};

const initialState = {
    token: "",
    isAuthenticated: false,
    errorMessage: "",
    isAuthChecked: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTION_TYPES[REDUCER_TYPE.LOGIN].SUCCESS:
            return {
                ...state,
                token: action.payload.data.token,
                isAuthenticated: true,
                errorMessage: ""
            };
        case ACTION_TYPES[REDUCER_TYPE.LOGIN].FAILED:
            return {
                ...state,
                token: "",
                isAuthenticated: false,
                errorMessage: action.error.response.data.message
            };
        case ACTION_TYPES[REDUCER_TYPE.AUTH_CHECK].SUCCESS:
            return {
                ...state,
                isAuthChecked: true,
                isAuthenticated: action.payload.data.isLogged,
                errorMessage: ""
            };
        case ACTION_TYPES[REDUCER_TYPE.AUTH_CHECK].FAILED:
            return {
                ...state,
                isAuthChecked: true,
                token: "",
                isAuthenticated: false,
            };
        case ACTION_TYPES[REDUCER_TYPE.LOGOUT]:
            return {
                ...state,
                isAuthChecked: true,
                token: "",
                isAuthenticated: false,
            };
        default:
            return state;
    }
}
