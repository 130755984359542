import React from 'react';
import './App.css';
import {Router} from "react-router";
import {history} from "./index";
import {MainRoutes} from "./pages";
import {ErrorBoundary} from "./components";

function App() {
    return (
        <Router history={history}>
            <ErrorBoundary>
                <MainRoutes/>
            </ErrorBoundary>
        </Router>
    );
}

export default App;
