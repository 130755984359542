import auth from './auth/reducer';
import users from './users/reducer';
import sidebar from './sidebar/reducer';
import form from './form/reducer';
import ui from './ui/reducer';

export default {
    auth,
    users,
    sidebar,
    form,
    ui

};
