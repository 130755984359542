export const sidebarRoutes = [{
    title: "Oldalak",
    icon: 'Layers',
    sub: [{
        title: 'Heti menü',
        src: 'oldalak/heti_menu'
    },{
        title: 'Üzleti ebéd',
        src: 'oldalak/uzleti_ebed'
    },/*{
        title: 'Étlap',
        src: 'oldalak/etlap'
    },{
        title: 'Pizzák',
        src: 'oldalak/pizzak'
    },{
        title: 'Sushi',
        src: 'oldalak/sushi'
    }*/]
},{
    title: 'Felhasználók',
    icon: "People",
    src: 'felhasznalok'
}];
