import makeStyles from "@material-ui/core/styles/makeStyles";
import {COLORS} from "../../theme";

export const useRemoteModalStyle = makeStyles((theme) => ({
    remoteDeleteModalRoot: {
        backgroundColor: COLORS.LOGO_COLOR_DARKER,
        color: '#fff'
    },
    remoteModalRoot: {
        backgroundColor: COLORS.MOBILE_NAV_ADMIN,
        color: '#fff'
    }
}));
