import {actionType} from "../utils/actionTypes";

const REDUCER_NAME = "form";
export const REDUCER_TYPE = {
    TOGGLE_EDIT: "TOGGLE_EDIT",
    SAVE_FORM: 'SAVE_FORM',
    GET_FORM: 'GET_FORM',
    CLEAR_FORM: 'CLEAR_FORM',
};

export const ACTION_TYPES = {
    ...actionType(REDUCER_NAME, REDUCER_TYPE.TOGGLE_EDIT, true),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.CLEAR_FORM, true),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.SAVE_FORM),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.GET_FORM),
};

const initialState = {
    isEdit: false,
};


export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTION_TYPES[REDUCER_TYPE.TOGGLE_EDIT]:
            return {
                ...state,
                isEdit: !state.isEdit
            };
        case ACTION_TYPES[REDUCER_TYPE.CLEAR_FORM]:
            return {
                ...state,
                [action.data.formName]: undefined
            };
        case ACTION_TYPES[REDUCER_TYPE.GET_FORM].SUCCESS:
            return {
                ...state,
                [action.payload.formName]: action.payload.data
            };
        case ACTION_TYPES[REDUCER_TYPE.SAVE_FORM].SUCCESS:
            return {
                ...state,
                isEdit: false,
                [action.payload.formName]: undefined
            };
        default:
            return state;
    }
}
