import React from "react";
import {FormBuilder} from "../../components";
import {userFormConstants} from "../../constants/pages/felhasznalok-constants";
import {useDidMount} from "../../hooks/useDidmount";
import {getAllusers, saveUsers} from "../../redux/users/action";
import {useSelector} from "react-redux";
import _ from 'lodash';

const FelhasznalokRoute = () => {

    const initialvalues = useSelector(state => state.users.form, _.isEqual);
    const pageLoading = useSelector(state => state.users.pageLoading, _.isEqual);
    //console.debug("initialvalues", initialvalues)
    useDidMount(() => {
        getAllusers()
    });

    const handleSubmit = (values) => {
        //console.debug("values", values);
        saveUsers(values)
    };

    return (
        <>
            {!pageLoading &&
            <FormBuilder initialValues={initialvalues} {...userFormConstants} onSubmit={handleSubmit} useIsEdit/>
            }
        </>
    )
}

export default FelhasznalokRoute;
