import makeStyles from "@material-ui/core/styles/makeStyles";
import {COLORS} from "../../theme";

export const useFormMenuTheme = makeStyles((theme) => ({
    buttonlabel: {
        margin: '0px !important'
    },
    buttonXsRoot: {
        padding: '6px 6px !important',
        minWidth: '50px !important',
        minHeight: '50px !important'
    },
    editButton: {
        backgroundColor: COLORS.EDIT_BUTTON + '!important',
        color: COLORS.MOBILE_NAV_ADMIN + '!important',
        '&:hover': {
            backgroundColor: COLORS.EDIT_BUTTON_HOVER + '!important'
        }
    },
    saveButton: {
        backgroundColor: COLORS.SAVE_BUTTON + '!important',
        color: COLORS.MOBILE_NAV_ADMIN + '!important',
        '&:hover': {
            backgroundColor: COLORS.SAVE_BUTTON_HOVER + '!important'
        }
    },
    deleteButton: {
        backgroundColor: COLORS.DELETE_BUTTON + '!important',
        color: '#fff !important',
        '&:hover': {
            backgroundColor: COLORS.DELETE_BUTTON_HOVER + '!important'
        }
    }
}));
