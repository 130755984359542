import {VALIDATION} from "../../components/formbuilder/validations";

export const hetiMenuFormConstants = {
    formName: "hetimenu",
    simpleForm: false,
    withFormMenu: true,
    content: [{
        panel: {
            title: 'Heti menü paraméterek',
            alwaysOpen: false,
            open: true,
        },
        fields: [{
            type: 'hidden',
            name: '_id'
        }, {
            type: "number",
            name: "params.price",
            label: "Ár helyben",
            size: "small",
            margin: "dense",
            flexWidth: 3,
            endText: 'Ft',
            validations: [VALIDATION.REQUIRED, VALIDATION.ONLY_NUMBER]
        },{
            type: "number",
            name: "params.priceWithShipping",
            label: "Ár kiszállítással",
            size: "small",
            margin: "dense",
            flexWidth: 3,
            endText: 'Ft',
            validations: [VALIDATION.REQUIRED, VALIDATION.ONLY_NUMBER]
        }, {
            type: "date",
            name: "params.startDate",
            label: 'Érvényességi idő kezdete',
            size: "small",
            margin: "dense",
            flexWidth: 3,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "params.endDate",
            label: 'Érvényességi idő vége',
            disabled: true,
            size: "small",
            margin: "dense",
            flexWidth: 3,
            validations: [VALIDATION.REQUIRED],
            wrapper: 'ParamsEndDateWrapper'
        },
            {
                type: "checkbox",
                name: "params.soldOut",
                label: "Elfogyott menük (csak akkor vásárolható online, ha NINCS bepipalva!)",
                size: "small",
                margin: "dense",
                flexWidth: 12,
                options: [{
                    label: 'A menü',
                    value: 'menuA'
                },{
                    label: 'B menü',
                    value: 'menuB'
                },{
                    label: 'A menü főétel',
                    value: 'menuAFo'
                },{
                    label: 'B menü főétel',
                    value: 'menuBFo'
                },{
                    label: 'Desszert',
                    value: 'dessert'
                },{
                    label: 'Leves',
                    value: 'leves'
                },]
            },
            {
                type: "number",
                name: "params.leves",
                label: "Ár leves",
                size: "small",
                margin: "dense",
                flexWidth: 3,
                endText: 'Ft',
                validations: [VALIDATION.REQUIRED, VALIDATION.ONLY_NUMBER]
            },{
                type: "number",
                name: "params.foEtel",
                label: "Ár főétel",
                size: "small",
                margin: "dense",
                flexWidth: 3,
                endText: 'Ft',
                validations: [VALIDATION.REQUIRED, VALIDATION.ONLY_NUMBER]
            },]
    }, {
        panel: {
            title: 'Desszert',
            alwaysOpen: false,
            open: false,
        },
        fields: [{
            type: "text",
            name: "dessert.name",
            label: "Desszert neve",
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: []
        }, {
            type: "number",
            name: "dessert.price",
            label: 'Desszert ára',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            endText: 'Ft',
            validations: []
        },
        ]
    }, {
        panel: {
            title: 'Hétfő',
            alwaysOpen: false,
            open: false,
        },
        fields: [{
            type: "text",
            name: "monday.leves",
            label: "Leves neve",
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "monday.aMenu",
            label: 'A menu',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "monday.bMenu",
            label: 'B menu',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        },
        ]
    }, {
        panel: {
            title: 'Kedd',
            alwaysOpen: false,
            open: false,
        },
        fields: [{
            type: "text",
            name: "tuesday.leves",
            label: "Leves neve",
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "tuesday.aMenu",
            label: 'A menu',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "tuesday.bMenu",
            label: 'B menu',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        },
        ]
    }, {
        panel: {
            title: 'Szerda',
            alwaysOpen: false,
            open: false,
        },
        fields: [{
            type: "text",
            name: "wednesday.leves",
            label: "Leves neve",
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "wednesday.aMenu",
            label: 'A menu',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "wednesday.bMenu",
            label: 'B menu',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        },
        ]
    }, {
        panel: {
            title: 'Csütörtök',
            alwaysOpen: false,
            open: false,
        },
        fields: [{
            type: "text",
            name: "thursday.leves",
            label: "Leves neve",
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "thursday.aMenu",
            label: 'A menu',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "thursday.bMenu",
            label: 'B menu',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        },
        ]
    }, {
        panel: {
            title: 'Péntek',
            alwaysOpen: false,
            open: false,
        },
        fields: [{
            type: "text",
            name: "friday.leves",
            label: "Leves neve",
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "friday.aMenu",
            label: 'A menu',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        }, {
            type: "text",
            name: "friday.bMenu",
            label: 'B menu',
            size: "small",
            margin: "dense",
            flexWidth: 4,
            validations: [VALIDATION.REQUIRED]
        },
        ]
    }]
};
