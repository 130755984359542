import store from "../store";
import {ACTION_TYPES, REDUCER_TYPE} from "./reducer";

export const openRemoteModal = (data) => store.dispatch({type: ACTION_TYPES[REDUCER_TYPE.OPEN_REMOTE_MODAL], data});
export const closeRemoteModal = () => store.dispatch({type: ACTION_TYPES[REDUCER_TYPE.CLOSE_REMOTE_MODAL]});
export const removeSnackbar = (key) => {
    const data = {
        key,
    }
    return store.dispatch({type: ACTION_TYPES[REDUCER_TYPE.REMOVE_SNACKBAR], data});
};
export const closeSnackbar = (key) => {
    const data = {
        dismissAll: !key, // dismiss all if no key has been defined
        key,
    }
    return store.dispatch({type: ACTION_TYPES[REDUCER_TYPE.CLOSE_SNACKBAR], data});
};
export const setSnackbarMsg = (notification = {}) => {
    const key = notification.options && notification.options.key;
    const data = {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
    };
    return store.dispatch({type: ACTION_TYPES[REDUCER_TYPE.ENQUEUE_SNACKBAR], data});
};
