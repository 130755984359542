import makeStyles from "@material-ui/core/styles/makeStyles";
import {COLORS} from "../../index";

export const useCheckBoxInputTheme = makeStyles((theme) => ({
    checkboxRoot: {
        color: COLORS.MOBILE_NAV_ADMIN + '!important',
        '&$checked': {
            color: COLORS.MAIN_TITLE_GOLD + '!important',
        },
    },
}));
