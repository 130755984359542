import React from "react";
import {useSelector} from "react-redux";
import {useTextInputTheme} from "../../../../theme/components/formbuilder/textinput-theme";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import moment from 'moment';

const DatePikcerInput = (props) => {
    const {
        variant = "outlined",
        margin = "dense",
        required = false,
        disabled = false,
        useIsEdit = false,
        rules,
        id = "",
        label = "",
        size = "small",
        flexWidth = 6,
        type,
        name,
        error,
        register,
        setValue,
        defaultValue = "",
        endText = ""
    } = props;
    const classes = useTextInputTheme();
    const isEdit = useSelector(state => state.form.isEdit);
    const isDisabled = disabled ? disabled : useIsEdit ? !isEdit : disabled;

    const handleOnChange = (e) => {
        setValue(name, e.target.value)
    };

    return (
        <Grid item xs={12} sm={flexWidth}>
            <TextField
                autoComplete={"off"}
                defaultValue={defaultValue || moment().format('YYYY-MM-DD')}
                onChange={handleOnChange}
                inputRef={register(rules)}
                name={name}
                type={type}
                variant={variant}
                margin={margin}
                disabled={isDisabled}
                fullWidth
                id={id}
                label={`${label} ${required ? '*' : ''}`}
                size={size}
                error={Boolean(error)}
                helperText={error && error.message}
                InputLabelProps={{
                    shrink: true,
                    disableAnimation: true
                }}
                InputProps={{
                    classes: {
                        disabled: classes.disabled
                    },
                    endAdornment: endText
                }}
            />
        </Grid>

    )
}

export default DatePikcerInput;
