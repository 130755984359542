import React from "react";
import {useFormMenuTheme} from "./style";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Edit, RotateLeft, Save} from "@material-ui/icons"
import {isWidthDown, isWidthUp} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import classNames from 'classnames';
import {toggleFormEdit} from "../../redux/form/action";
import {useSelector} from "react-redux";
import {useFormContext} from "react-hook-form";

const FormMenu = (props) => {
    const classes = useFormMenuTheme();
    const {reset, formState} = useFormContext()
    const isEdit = useSelector(state => state.form.isEdit);

    const isMdUp = () => isWidthUp("md", props.width);
    const isXsDown = () => isWidthDown("xs", props.width);

    const handleEditClick = () => {
        toggleFormEdit()
    };

    const handleREset = () => {
        reset();
        toggleFormEdit()
    };

    return (
        <Grid container justify={isMdUp() ? "flex-end" : "center"} spacing={isMdUp() ? 2 : 4}>
            <Grid item>
                {!isEdit && <Button variant={"contained"}
                                    onClick={handleEditClick}
                                    classes={{
                                        root: classNames(classes.editButton, {[classes.buttonXsRoot]: isXsDown()}),
                                        startIcon: classNames({[classes.buttonlabel]: isXsDown()})
                                    }}
                                    size={isMdUp() ? "medium" : "large"}
                                    startIcon={<Edit/>}>
                    {!isXsDown() ? "Szerkesztés" : ''}</Button>}
            </Grid>
            <Grid item>
                <Button variant={"contained"}
                        type={"submit"}
                        disabled={!formState.isDirty}
                        classes={{
                            root: classNames({
                                [classes.saveButton]: formState.isDirty,
                                [classes.buttonXsRoot]: isXsDown()
                            }),
                            startIcon: classNames({[classes.buttonlabel]: isXsDown()}),
                        }}
                        size={isMdUp() ? "medium" : "large"}
                        startIcon={<Save/>}>
                    {!isXsDown() ? "Mentés" : ""}</Button>
            </Grid>
            <Grid item>
                <Button variant={"contained"}
                        onClick={handleREset}
                        disabled={!isEdit}
                        classes={{
                            root: classNames({
                                [classes.deleteButton]: isEdit,
                                [classes.buttonXsRoot]: isXsDown()
                            }),
                            startIcon: classNames({[classes.buttonlabel]: isXsDown()})
                        }}
                        size={isMdUp() ? "medium" : "large"}
                        startIcon={<RotateLeft/>}>
                    {!isXsDown() ? "Visszaállítás" : ''}</Button>
            </Grid>
        </Grid>
    )
}

export default withWidth()(FormMenu);
