import React from "react";
import {ValidateField} from "../../formBuilder";
import CheckBoxInput from "../inputs/checkboxInput";

export const CheckboxDecider = (fieldObject) => {
    const {type, name} = fieldObject;
    switch (type) {
        case 'checkbox':
            return (
                <React.Fragment key={name}>
                    <ValidateField {...fieldObject} component={<RenderCheckbox/>}/>
                </React.Fragment>);
        default: return false
    }
};


const RenderCheckbox = (props) => {
    return <CheckBoxInput {...props}/>
};
