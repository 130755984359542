import React from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar/AppBar";
import {useToolbarTheme} from "../../theme/components/page/useToolbarTheme";
import {toggleSidebarAction} from "../../redux/sidebar/action";
import {useSelector} from "react-redux";
import {ExitToApp, Menu, MenuOpen} from '@material-ui/icons'
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import {removeToken} from "../../config/localStorageUtiles";
import {handleLogoutAction} from "../../redux/auth/action";
import {goPage} from "../../functions";
import {isWidthUp} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import {openRemoteModal} from "../../redux/ui/action";
import Grid from "@material-ui/core/Grid";
import _ from 'lodash';

const Navigationbar = (props) => {
    const classes = useToolbarTheme();

    const open = useSelector(state => state.sidebar.open);
    const mainTitle = useSelector(state => state.sidebar.mainTitle);
    const profile = useSelector(state => state.users.profile);
    const handleDrawerOpen = () => toggleSidebarAction(true);
    const toggleDrawer = () => toggleSidebarAction();

    const logout = () => {
        removeToken().then(() => {
            handleLogoutAction();
            goPage("/");
            document.location.reload()
        })
    };

    const handleLogOutWithDialog = () => {
        openRemoteModal({
            remoteModalOpen: true,
            title: `Biztos kijelentkezik?`,
            content: `Ha kijelentkezéssel megszakítja az aktuális munkamenetet. Ha újra bejelentkezik ismét eléri az alábbi felületet.`,
            okButton: {
                title: 'Kijelentkezés',
                fn: () => logout()
            }
        })
    };

    const isMdUp = () => {
        return isWidthUp("md", props.width)
    };

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                {isMdUp() ? <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, classes.iconAndTextColor, open && classes.menuButtonHidden)}
                    >
                        <Menu/>
                    </IconButton> :
                    <IconButton onClick={toggleDrawer}>
                        {open ? <MenuOpen className={classes.iconAndTextColor}/> :
                            <Menu className={classes.iconAndTextColor}/>}
                    </IconButton>}
                <Typography component="h1" variant="h6" color="inherit" className={classes.title}>
                    {mainTitle}
                </Typography>
                <Grid container direction={"row"} justify={"flex-end"} alignItems={'center'}>
                    {!_.isEmpty(profile) && <p className={classes.profileName}>{`${profile.firstname} ${profile.lastname}`}</p>}
                    <Tooltip title={"Kijelentkezés"}>
                        <IconButton color="inherit" onClick={handleLogOutWithDialog}>
                            <ExitToApp className={classes.iconAndTextColor}/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Toolbar>
        </AppBar>
    )
};

export default withWidth()(Navigationbar);
