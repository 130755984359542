import React, {useState} from "react";
import {useExpPanelTheme} from "./style";
import Typography from "@material-ui/core/Typography";
import {ExpandMore} from "@material-ui/icons";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const ExpPanel = (props) => {
    const {title, alwaysOpen, open, content = ""} = props;
    const classes = useExpPanelTheme();
    const [expanded, setExpanded] = useState(alwaysOpen || open || false);

    const handleExpanded = () => {
        if(!alwaysOpen) {
            setExpanded(!expanded);
        }
    };

    return (
        <div className={classes.root}>
            <Accordion expanded={expanded} onChange={handleExpanded}>
                <AccordionSummary expandIcon={!alwaysOpen && <ExpandMore className={classes.expandIcon}/>} classes={{
                    root: classes.summaryRoot
                }}>
                    <Typography className={classes.heading}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {("string" === typeof content) ? <Typography>
                        {content}
                    </Typography> : <>{content}</>}
                </AccordionDetails>
            </Accordion>
        </div>
    )
};

export default ExpPanel;
