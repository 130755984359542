import React from "react";
import {FormBuilder} from "../../components";
import {pizzakFormConstants} from "../../constants/pages/pizzak-constants";

const Pizzak = () => {
    const handleSubmit = (values) => {
        console.debug("values", values)
    };

    const initialValues = {
        pizzak: [
            {etelNev: "Étel1", etelLeiras: "leiras1", etelAr: "1", etelArBig: "10", icons: ['fish']},
            {etelNev: "Étel2", etelLeiras: "leiras2", etelAr: "2", etelArBig: "20", icons: ['fish', 'vegan']},
            {etelNev: "Étel3", etelLeiras: "leiras3", etelAr: "3", etelArBig: "30", icons: ['fish', 'chili']},
        ]
    }

    return <FormBuilder {...pizzakFormConstants} onSubmit={handleSubmit} useIsEdit initialValues={initialValues}/>
}

export {Pizzak};
