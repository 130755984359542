import {VALIDATION} from "../../components/formbuilder/validations";

export const  loginFormConstants = {
    formName: "login",
    simpleForm: true,
    fields: [{
        type: "text",
        name: "username",
        label: "Felhsználó",
        size: "medium",
        margin: "normal",
        flexWidth: 12,
        validations: [VALIDATION.REQUIRED]
    }, {
        type: "password",
        name: "password",
        label: "Jelszó",
        size: "medium",
        margin: "normal",
        flexWidth: 12,
        validations: [VALIDATION.REQUIRED]
    }]
};
