import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {Copyright} from "../../pages/loginpage";
import {useAppContentTheme} from "../../theme/components/page/useAppContentTheme";
import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";
import classNames from 'classnames';

const AppContent = (props) => {
    const open = useSelector(state => state.sidebar.open);

    const classes = useAppContentTheme();
    const {children} = props;
    return (
        <div className={classNames(classes.root, {
            [classes.rootOpen]: open
        })}>
            <CssBaseline/>
            <div className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <Container maxWidth="lg" className={classes.container}>
                    {children}
                    <Box pt={4}>
                        <Grid container justify={"center"}>
                            <Copyright/>
                        </Grid>
                    </Box>
                </Container>
            </div>
        </div>
    );
}

export default AppContent;
