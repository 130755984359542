import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./redux/store";
import {createBrowserHistory} from "history"
import axiosInterceptor from "./config/axios-interceptor";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {defaultTheme, googleFonts} from "./theme";
import GoogleFontLoader from "react-google-font-loader";
import 'animate.css';
import 'moment/locale/hu';
import {SnackbarProvider} from "notistack";

export const rootEl = document.getElementById('root');

axiosInterceptor(store);
export const history = createBrowserHistory();
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

ReactDOM.render(
    <Provider store={store}>
        <GoogleFontLoader fonts={googleFonts}/>
        <MuiThemeProvider theme={defaultTheme}>
            <SnackbarProvider maxSnack={3}>
                <App/>
            </SnackbarProvider>
        </MuiThemeProvider>
    </Provider>,
    rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
