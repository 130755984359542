export const actionType = (reducerName, type, singleType = false) => singleType ?
    {
        [type.toUpperCase()]: `[${reducerName.toUpperCase()}] - ${type.toUpperCase()}`
    } : {
        [type.toUpperCase()]: {
            FETCH : `[${reducerName.toUpperCase()}] - ${type.toUpperCase()} - (FETCHING)`,
            SUCCESS : `[${reducerName.toUpperCase()}] - ${type.toUpperCase()} - (SUCCESS)`,
            FAILED : `[${reducerName.toUpperCase()}] - ${type.toUpperCase()} - (FAILED)`
        }
    };
