export const VALIDATION = {
    REQUIRED: "required",
    EMAIL: "email",
    MIN_LENGTH: "minLength",
    NOT_SAME_PASSWORD: "passwordIsSame",
    PASSWORD_STRENGTH: "passwordStrength",
    ONLY_NUMBER: "onlyNumber"
};

const errorMessages = (customText) => ({
    [VALIDATION.REQUIRED]: 'Mező kitöltése kötelező!',
    [VALIDATION.EMAIL]: 'Nem megfelelő email formátum!',
    [VALIDATION.MIN_LENGTH]: `Mező hosszának minimum ${customText} karakternek kell lennie!`,
    [VALIDATION.NOT_SAME_PASSWORD]: `Jelszó nem egyezik!`,
    [VALIDATION.PASSWORD_STRENGTH]: `Jelszó legalább 8 karatkerből áll. Tartalmaznia kell kis és nagybetút valamint számot.!`,
    [VALIDATION.ONLY_NUMBER]: `Csak számokat tartalmazhat!`
});


export const validationObj = {

    [VALIDATION.REQUIRED]: (value = "") => {
        if (value === null) value = "";

        if (typeof (value) === "object") {
            return false
        } else {
            return !value || value.replace(/[\W_]+/g, "") === ""
        }
    },

    [VALIDATION.NOT_SAME_PASSWORD]: (validValue, getValues, fieldArrayDetails) => (value) => {
        const fullFieldArrayName = validValue && fieldArrayDetails ?
            validValue.replace('$index$', fieldArrayDetails.fieldArrayIndex) : validValue;
        if (!getValues(fullFieldArrayName)) return false;
        return getValues(fullFieldArrayName) !== value

    },

    [VALIDATION.PASSWORD_STRENGTH]: (value) => {
        if (!value) return false;
        return value && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(value)
    },

    alphaNumeric: value => value && !/^[a-záéúöőóüűí0-9]+$/i.test(value),

    [VALIDATION.ONLY_NUMBER]:
        value => value && !/^[0-9]\d*$/g.test(value),

    maxLength: (validValue) => (value) => {
        return value && value.replace(/\s/g, "").length > validValue;
    },

    [VALIDATION.MIN_LENGTH]:
        (validValue) => (value) => {
            if (value && value.replace(/\s/g, "").length === 0) {
                return false
            } else {
                return value && value.replace(/\s/g, "").length < validValue;
            }

        },

    number:
        value => value && isNaN(Number(value)),

    [VALIDATION.EMAIL]:
        value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value),
};

export const composeValidators = (args, getValues, fieldArrayDetails) => (value) => {
    for (const validator of args) {
        let error = "";
        if (typeof (validator) === "object") {
            const {validationName, validationValue} = validator;
            error = validationObj[validationName](validationValue, getValues, fieldArrayDetails)(value) && errorMessages(validationValue)[validationName]
        } else {
            error = validationObj[validator](value) && errorMessages()[validator];
        }
        if (error) {
            return error;
        }
    }

    return undefined;
};
