import makeStyles from "@material-ui/core/styles/makeStyles";
import COLORS from "../../theme/colors";

export const useExpPanelTheme = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 10
    },
    heading: {
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.pxToRem(15),
            minWidth: '100%',
        },
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    expandIcon: {
      color: COLORS.MAIN_TITLE_GOLD
    },
    summaryRoot: {
        backgroundColor: COLORS.MOBILE_NAV,
        color: COLORS.MAIN_TITLE_GOLD,
        fontWeight: 'bolder',
        maxHeight: 50,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6
    }
}));
