import {Layers, People} from "@material-ui/icons";

export const getIcon = (icon) => {
    switch (icon) {
        case ("Layers"):
            return Layers;
        case ("People"):
            return People;
        default:
            return null
    }
};
