import {useEffect, useRef} from "react";
import {useWatch} from "react-hook-form";
import {useDidMount} from "../../../../../hooks/useDidmount";
import moment from "moment";

const ParamsEndDateWrapper = (props) => {
    const {setValue, name} = props;
    const didMountRef = useRef(false);
    const currentValue = useWatch({name: 'params.startDate'});


    useEffect(() => {
        if (didMountRef.current && currentValue) {
            const plusOneWeek = moment(currentValue).add(4, 'days').format('YYYY-MM-DD');
            setValue(name, plusOneWeek)
        }
    }, [currentValue, setValue, name]);

    useDidMount(() => {
        didMountRef.current = true;
    });

    return props.children
}

export default ParamsEndDateWrapper;
