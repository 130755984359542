import makeStyles from "@material-ui/core/styles/makeStyles";
import {drawerWidth} from "../../../config/drawerbar-config";
import {COLORS} from "../../index";

export const useToolbarTheme = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: COLORS.MOBILE_NAV
    },
    iconAndTextColor: {
        color: COLORS.MAIN_TITLE_GOLD
    },
    profileName: {
        color: COLORS.MAIN_TITLE_GOLD,
        padding: 0,
        margin: 0,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },

    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        whiteSpace: 'nowrap'
    },
}));
