const COLORS = {
    MENU_TEXT: "#111",
    //LOGO_COLOR: '#820000'
    LOGO_COLOR: '#db3135',
    LOGO_COLOR_TRANSPARENT: 'rgb(219,49,53, 0.6)',
    LOGO_COLOR_SUPER_TRANSPARENT: 'rgb(219,49,53, 0.1)',
    LOGO_COLOR_DARKER: '#9c2829',
    MAIN_TITLE_LIGHT: "#34384c",
    MAIN_TITLE_GOLD: '#e0d1b3',
    MAIN_TITLE_GOLD_LIGHT: '#f1e2c4',
    DAY_MENU_LIST_BORDER: "#fbd5d7",
    A_MENU_COLOR: '#6bef5f',
    B_MENU_COLOR: '#44bcef',
    MENU_ITEM_SUB: '#57667E',
    MENU_ITEM_BORDER: '#E1E6EB',
    FOOTER_BG: "#021127",
    FOOTER_TEXT: "#707B8E",
    MOBILE_NAV: 'rgba(2,17,39, 0.95)',
    MOBILE_NAV_ADMIN: 'rgba(2,17,39)',
    OVERLAY: 'rgba(2,17,39, 0.9)',
    MOBILE_NAV_BORDER: 'rgba(112,123,142, 0.3)',
    EDIT_BUTTON: "#ffaf1e",
    EDIT_BUTTON_HOVER: "#a36e18",
    SAVE_BUTTON: '#6bef5f',
    SAVE_BUTTON_HOVER: '#4d9a44',
    DELETE_BUTTON: '#db3135',
    DELETE_BUTTON_HOVER: '#9d2929',
};
export default COLORS
