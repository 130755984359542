import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router";
import {Dashboard, LoginPage, NotFound} from "./index";
import {useSelector} from "react-redux";
import {authCheckAction} from "../redux/auth/action";
import {AppContent, NavigationBar, SideBar} from "../components";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FelhasznalokRoute from "./Felhasznalok";
import {Etlap} from "./Etlap";
import {RemoteModal} from "../components/RemoteModal";
import {Pizzak} from "./Pizzak";
import {Sushi} from "./Sushi";
import {HetiMenu} from "./HetiMenu";
import {UzletiEbed} from "./UzletiEbed";
import Toast from "../components/Toast";
import _ from 'lodash';
import {getProfileAction} from "../redux/users/action";

const useLoadingstyle = makeStyles((theme) => ({
    root: {
        minHeight: '100vh'
    }
}));

const MainRoutes = () => {
    const [, setNotFound] = useState(false);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const isAuthChecked = useSelector(state => state.auth.isAuthChecked);
    const profile = useSelector(state => state.users.profile);
    const classes = useLoadingstyle();

    useEffect(() => {
        if (!isAuthenticated && !isAuthChecked) {
            authCheckAction()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(isAuthenticated && _.isEmpty(profile)) {
            getProfileAction();
        }
    }, [isAuthenticated, profile]);

    if (!isAuthChecked) {
        return <Grid container justify={"center"} alignItems={"center"} className={classes.root}>
            <CircularProgress color="secondary"/>
        </Grid>
    }

    if (isAuthenticated) {
        return (
            <div style={{display: "flex"}}>
                <NavigationBar/>
                <SideBar/>
                <AppContent>
                    <Switch>
                        <Route exact path={"/"} component={Dashboard}/>
                        <Route exact path={"/felhasznalok"} component={FelhasznalokRoute}/>
                        <Route exact path={"/oldalak/heti_menu"} component={HetiMenu}/>
                        <Route exact path={"/oldalak/uzleti_ebed"} component={UzletiEbed}/>
                        <Route exact path={"/oldalak/etlap"} component={Etlap}/>
                        <Route exact path={"/oldalak/pizzak"} component={Pizzak}/>
                        <Route exact path={"/oldalak/sushi"} component={Sushi}/>
                        <Route component={() => <NotFound setNotFound={setNotFound}/>}/>
                    </Switch>
                </AppContent>
                <RemoteModal/>
                <Toast/>
            </div>
        )
    }
    return (
        <>
            <Switch>
                <Route exact path={"/"} component={LoginPage}/>
                <Route component={() => <NotFound setNotFound={setNotFound}/>}/>
            </Switch>
            <Toast/>
            {/*  {!notFound && <Footer/>}*/}
        </>
    )
};

export default MainRoutes
