import React from "react";
import {TextInputDecider} from "./fields/deciders/textInputDecider";
import {useFormBuilderTheme} from "../../theme/components/formbuilder/formBuilder-theme";
import {composeValidators, VALIDATION} from "./validations";
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import FormMenu from "../FormMenu";
import ExpPanel from "../ExpPanel";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {useMediaQuery, useTheme} from "@material-ui/core";
import {FieldArrayDecider} from "./fields/deciders/fieldArrayDecider";
import _ from 'lodash';
import {HiddenInputDecider} from "./fields/deciders/hiddenInputDecider";
import {CheckboxDecider} from "./fields/deciders/checkboxDecider";
import {StyleDecider} from "./fields/deciders/styleDecider";
import {DatepickerDecider} from "./fields/deciders/datePickerDecider";

export const remoteSubmit = (name) => {
    let event;
    if (typeof (Event) === "function") {
        event = new Event("submit")
    } else {
        event = document.createEvent("Event");
        event.initEvent('submit', true, true)
    }
    document.getElementById(`${name}-form`).dispatchEvent(event)
};

export const ValidateField = ({validations = [], component, ...restProps}) => {
    const {register, setValue, getValues, errors} = useFormContext();
    const required = validations.some((validation) => validation === VALIDATION.REQUIRED);
    const rules = {validate: composeValidators(validations, getValues, restProps.fieldArrayDetails)};
    return React.cloneElement(component, {
        ...restProps,
        error: _.get(errors, restProps.name),
        register,
        setValue,
        getValues,
        required,
        rules
    })
};

export const formFieldComposer = ({field, useIsEdit, fieldArrayDetails, defaultValues, initialValues}) => {
    const uniqueCompose = (...functions) => (fieldObject) => {
        let res = false;
        if (!functions.find((singleFunction) => {
            res = singleFunction(fieldObject);
            return res;
        }))
            throw Error(JSON.stringify(fieldObject));
        return res;
    };

    field.useIsEdit = useIsEdit;
    field.fieldArrayDetails = fieldArrayDetails;
    if(!_.isEmpty(fieldArrayDetails)) {
        field.defaultValues = defaultValues;
    } else {
        field.defaultValue = _.get(initialValues, field.name, undefined);
    }

    if (fieldArrayDetails && !_.isEmpty(fieldArrayDetails)) {
        const {fieldArrayName, fieldArrayIndex} = fieldArrayDetails;
        field.name = `${fieldArrayName}[${fieldArrayIndex}].${field.name}`
    }
    // --- InputType Deciders ---
    return uniqueCompose(
        TextInputDecider,
        HiddenInputDecider,
        CheckboxDecider,
        StyleDecider,
        DatepickerDecider,
        FieldArrayDecider
    )(field)
};

const PanelBuilder = (props) => {
    const {panel, fields, useIsEdit, initialValues} = props;
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <>
            {panel && <ExpPanel {...panel} content={
                <Grid container spacing={isXsDown ? 0 : 2} direction={"row"} style={{width: '100%'}}>
                    {fields.map((field) => formFieldComposer({field, useIsEdit, initialValues}))}
                </Grid>}/>
            }
            {!panel && fields.map((field) => formFieldComposer({field, useIsEdit, initialValues}))}
        </>
    )
};

const FormBuilder = (props) => {
    const {formName, fields, content, simpleForm = true, initialValues = {}, onSubmit, withFormMenu, useIsEdit = false, children} = props;
    const classes = useFormBuilderTheme();
    const methods = useForm({
        reValidateMode: "onChange",
        mode: "onChange",
        criteriaMode: "firstError",
        defaultValues: initialValues
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formRoot} id={`${formName}-form`}>
                {withFormMenu && <FormMenu/>}
                <Box mt={5}>
                    {simpleForm && fields.map((field) => formFieldComposer({field, useIsEdit, initialValues}))}
                    {!simpleForm && content.map((contentObj, i) => <PanelBuilder key={i} {...contentObj}
                                                                                 useIsEdit={useIsEdit}
                                                                                 initialValues={initialValues}/>)}
                </Box>
                {children}
            </form>
        </FormProvider>
    )
};

export default FormBuilder;
