import React, {Component} from 'react';
import {Route} from "react-router-dom";
import {Switch} from "react-router";
import NotFound from "../pages/error/notFound";


class ErrorBoundary extends Component {
    state = {isError: false};

    componentDidCatch(error, errorInfo) {
        this.setState({isError: true})
    }

    render() {
        if (this.state.isError) {
            return (
                <React.Fragment>
                    <Switch>
                        <Route component={() => <NotFound error/>}/>
                    </Switch>
                </React.Fragment>
            )
        } else {
            return this.props.children
        }

    }
}

export default ErrorBoundary;
