import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import reducers from '../redux';
import {sagas} from "./sagas";


const sagaMiddleware = createSagaMiddleware();
const logger = createLogger({ diff: true });

const middlewareEnhancer = (() =>
    process.env.NODE_ENV === 'development'
        ? composeWithDevTools(applyMiddleware(sagaMiddleware, logger))
        : applyMiddleware(sagaMiddleware))();

export default createStore(
    combineReducers(reducers),
    undefined,
    middlewareEnhancer,
);
for (let saga of sagas) {
    sagaMiddleware.run(saga)
}

