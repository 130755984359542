import React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";
import _ from 'lodash';


const Dashboard = () => {
    const profile = useSelector(state => state.users.profile);
    return (
        <Grid container>
            {!_.isEmpty(profile) && <Typography variant={"h5"}>{`Üdvözlöm ${profile.firstname} ${profile.lastname}!`}</Typography>}
        </Grid>
    );
};

export default Dashboard
