import React, {useEffect} from "react";
import {FormBuilder} from "../../components";
import {uzletiEbedFormConstants} from "../../constants/pages/uzletiEbed-constants";
import {useSelector} from "react-redux";
import {clearForm, getForm, saveForm} from "../../redux/form/action";
import {useDidMount} from "../../hooks/useDidmount";
import _ from 'lodash';
import LoadingIndicator from "../../components/LoadingIndicator";

const UzletiEbed = () => {
    const formName = 'bussinesLunch';
    const initialValues = useSelector((state) => state.form[formName]);

    const handleSubmit = (values) => {
        if (!_.isEmpty(values)) {
            saveForm({bussinesLunch: values.bussinesLunch, formName})
        }
    };

    useEffect(() => {
        if (!initialValues) {
            getForm({formName})
        }
    }, [initialValues]);

    useDidMount(() => {
    }, () => {
        clearForm({formName})
    });

    return (
        <>
            {initialValues ?
                <FormBuilder {...uzletiEbedFormConstants} onSubmit={handleSubmit} useIsEdit
                             initialValues={initialValues}/> : <LoadingIndicator/>}
        </>
    )
};

export {UzletiEbed};
