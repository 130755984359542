import {actionType} from "../utils/actionTypes";

const REDUCER_NAME = "users";
export const REDUCER_TYPE = {
    GET_ALL_USERS: "GET_ALL_USERS",
    SAVE_USERS: "SAVE_USERS",
    PROFILE: 'PROFILE'
};

export const ACTION_TYPES = {
    ...actionType(REDUCER_NAME, REDUCER_TYPE.GET_ALL_USERS),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.SAVE_USERS),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.PROFILE)
};

const initialState = {
    form: {},
    pageLoading: false,
    profile: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTION_TYPES[REDUCER_TYPE.GET_ALL_USERS].FETCH:
            return {
                ...state,
                pageLoading: true
            };
        case ACTION_TYPES[REDUCER_TYPE.GET_ALL_USERS].SUCCESS:
            return {
                ...state,
                form: action.payload.data,
                pageLoading: false
            };
        case ACTION_TYPES[REDUCER_TYPE.PROFILE].SUCCESS:
            return {
                ...state,
                profile: action.payload.data
            };
        case ACTION_TYPES[REDUCER_TYPE.GET_ALL_USERS].FAILED:
            return {
                ...state,
                pageLoading: false
            };
        default:
            return state;
    }
}
