import {COLORS} from "../index";

const bigButtonTheme = () => ({
    bigButton: {
        transition: 'all .2s ease-in-out',
        cursor: "pointer",
        backgroundColor: COLORS.LOGO_COLOR,
        paddingLeft: 25,
        paddingRight: 25,
        width: 'fit-content',
        color: "#fff",
        fontFamily: "Muli",
        fontWeight: 400,
        fontSize: "18px",
        '&:hover': {
            backgroundColor: COLORS.LOGO_COLOR_DARKER,
            color: '#d1d1d1'
        }
    }
});

export default bigButtonTheme()
