import React from "react";
import TextInput from "../inputs/textinput";
import {ValidateField} from "../../formBuilder";
import ParamsEndDateWrapper from "../FieldWrappers/ParamsEndDateWrapper";

export const TextInputDecider = (fieldObject) => {
    const {type, name} = fieldObject;
    switch (type) {
        case 'number':
        case 'text':
        case 'password':
            return (
                <React.Fragment key={name}>
                    <ValidateField {...fieldObject} component={<RenderTextField/>}/>
                </React.Fragment>);
        default:
            return false
    }
};


const RenderTextField = (props) => {
    if (props.wrapper === 'ParamsEndDateWrapper') {
        return (
            <ParamsEndDateWrapper setValue={props.setValue} name={props.name}>
                <TextInput {...props}/>
            </ParamsEndDateWrapper>
        )
    } else {
        return <TextInput {...props}/>
    }
};
