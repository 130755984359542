import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {useSelector} from "react-redux";
import {closeRemoteModal} from "../../redux/ui/action";
import {useRemoteModalStyle} from "./style";
import classNames from 'classnames';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RemoteModal = () => {
    const modalObj = useSelector((state) => state.ui.remoteModalObject);
    const {remoteModalOpen, title, content, okButton, style} = modalObj;
    const classes = useRemoteModalStyle();
    const handleOk = () => {
        okButton.fn();
        closeRemoteModal();
    };

    return (
        <Dialog
            open={remoteModalOpen}
            TransitionComponent={Transition}
            onClose={closeRemoteModal}
            maxWidth={"md"}
            fullWidth
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" classes={{
                root: classNames({
                    [classes.remoteModalRoot]: !style,
                    [classes.remoteDeleteModalRoot]: style === "delete"
                })
            }}>{title}</DialogTitle>
            <DialogContent>
                {"string" === typeof (content) ?
                    <DialogContentText id="alert-dialog-slide-description">
                        {content}
                    </DialogContentText> :
                    <>
                        {content}
                    </>}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeRemoteModal} color="secondary">
                    Mégse
                </Button>
                <Button onClick={handleOk} color="primary">
                    {okButton.title}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export {RemoteModal}
