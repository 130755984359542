import {VALIDATION} from "../../components/formbuilder/validations";

export const EtlapFormConstants = {
    formName: "etlap",
    simpleForm: false,
    withFormMenu: true,
    content: [{
        panel: {
            title: 'Étlap',
            alwaysOpen: true,
            open: false,
        },
        fields: [{
            type: "fieldArray",
            name: "etlap",
            mainPanel: true,
            addButtonName: 'Kategória hozzáadása',
            watchedField: "etlapKategoriaNev",
            fieldArrayFields: [
                {
                    type: "text",
                    name: "etlap[$index$].etlapKategoriaNev",
                    label: "Étel kategória neve",
                    size: "small",
                    margin: "dense",
                    flexWidth: 10,
                    validations: [VALIDATION.REQUIRED]
                },
                {
                    type: "fieldArray",
                    name: "etlap[$index$].etelkategoria",
                    addButtonName: 'Étel hozzáadása',
                    watchedField: "etelNev",
                    fieldArrayFields: [
                        {
                            type: "text",
                            name: "etlap[$index$].etelkategoria[$index$].etelNev",
                            label: "Étel neve",
                            size: "small",
                            margin: "dense",
                            flexWidth: 4,
                            validations: [VALIDATION.REQUIRED]
                        }, {
                            type: "text",
                            name: "etlap[$index$].etelkategoria[$index$].etelLeiras",
                            label: "Étel leírás",
                            size: "small",
                            margin: "dense",
                            flexWidth: 4,
                            validations: [VALIDATION.REQUIRED]
                        }, {
                            type: "number",
                            name: "etlap[$index$].etelkategoria[$index$].etelAr",
                            label: "Étel Ára",
                            size: "small",
                            margin: "dense",
                            flexWidth: 4,
                            endText: 'Ft',
                            validations: [VALIDATION.REQUIRED]
                        }, {
                            type: "checkbox",
                            name: "etlap[$index$].etelkategoria[$index$].icons",
                            label: "Megjelenő ikonok",
                            size: "small",
                            margin: "dense",
                            flexWidth: 10,
                            options: [{
                                label: 'Halas étel',
                                value: 'fish'
                            },{
                                label: 'Vegán étel',
                                value: 'vegan'
                            },{
                                label: 'Csípős étel',
                                value: 'chili'
                            }]
                        }
                    ]
                }
            ]
        }
        ]
    }]
};
