import {actionType} from "../utils/actionTypes";

const REDUCER_NAME = "ui";
export const REDUCER_TYPE = {
    OPEN_REMOTE_MODAL: "OPEN_REMOTE_MODAL",
    CLOSE_REMOTE_MODAL: "CLOSE_REMOTE_MODAL",
    ENQUEUE_SNACKBAR: "ENQUEUE_SNACKBAR",
    REMOVE_SNACKBAR: "REMOVE_SNACKBAR",
    CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
};

export const ACTION_TYPES = {
    ...actionType(REDUCER_NAME, REDUCER_TYPE.OPEN_REMOTE_MODAL, true),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.CLOSE_REMOTE_MODAL, true),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.ENQUEUE_SNACKBAR, true),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.REMOVE_SNACKBAR, true),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.CLOSE_SNACKBAR, true),
};
const initRemoteModalObject = {
    remoteModalOpen: false,
    title: '',
    style: '',
    content: null,
    okButton: {
        title: '',
        fn: null
    }
};
const initialState = {
    remoteModalObject: initRemoteModalObject,
    toast: []
};


export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTION_TYPES[REDUCER_TYPE.OPEN_REMOTE_MODAL]:
            return {
                ...state,
                remoteModalObject: {
                    remoteModalOpen: true,
                    ...action.data
                }
            };
        case ACTION_TYPES[REDUCER_TYPE.CLOSE_REMOTE_MODAL]:
            return {
                ...state,
                remoteModalObject: initRemoteModalObject
            };
        case ACTION_TYPES[REDUCER_TYPE.ENQUEUE_SNACKBAR]:
            return {
                ...state,
                toast: [...state.toast, action.data]
            };
        case ACTION_TYPES[REDUCER_TYPE.REMOVE_SNACKBAR]:
            return {
                ...state,
                toast: state.toast.filter(
                    notification => notification.key !== action.data.key,
                ),
            };
        case ACTION_TYPES[REDUCER_TYPE.CLOSE_SNACKBAR]:
            return {
                ...state,
                toast: state.toast.map(notification => (
                    (action.data.dismissAll || notification.key === action.data.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };
        default:
            return state;
    }
}
