import {VALIDATION} from "../../components/formbuilder/validations";

export const pizzakFormConstants = {
    formName: "pizzak",
    simpleForm: false,
    withFormMenu: true,
    content: [{
        panel: {
            title: 'Pizzák',
            alwaysOpen: true,
            open: false,
        },
        fields: [{
            type: "fieldArray",
            name: "pizzak",
            addButtonName: 'Pizza hozzáadása',
            watchedField: "etelNev",
            fieldArrayFields: [
                {
                    type: "text",
                    name: "pizzak[$index$].etelNev",
                    label: "Pizza neve",
                    size: "small",
                    margin: "dense",
                    flexWidth: 3,
                    validations: [VALIDATION.REQUIRED]
                }, {
                    type: "text",
                    name: "pizzak[$index$].etelLeiras",
                    label: "Pizza leírás",
                    size: "small",
                    margin: "dense",
                    flexWidth: 3,
                    validations: [VALIDATION.REQUIRED]
                }, {
                    type: "number",
                    name: "pizzak[$index$].etelAr",
                    label: "Pizza Ára (30cm)",
                    size: "small",
                    margin: "dense",
                    flexWidth: 3,
                    endText: 'Ft',
                    validations: [VALIDATION.REQUIRED]
                },
                {
                    type: "number",
                    name: "pizzak[$index$].etelArBig",
                    label: "Pizza Ára (33cm)",
                    size: "small",
                    margin: "dense",
                    flexWidth: 3,
                    endText: 'Ft',
                    validations: [VALIDATION.REQUIRED]
                },
                {
                    type: "checkbox",
                    name: "pizzak[$index$].icons",
                    label: "Megjelenő ikonok",
                    size: "small",
                    margin: "dense",
                    flexWidth: 10,
                    options: [{
                        label: 'Halas étel',
                        value: 'fish'
                    },{
                        label: 'Vegán étel',
                        value: 'vegan'
                    },{
                        label: 'Csípős étel',
                        value: 'chili'
                    }]
                }
            ]
        }
        ]
    }]
};
