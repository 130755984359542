import {actionType} from "../utils/actionTypes";

const REDUCER_NAME = "sidebar";
export const REDUCER_TYPE = {
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    SET_MAINTITLE: "SET_MAINTITLE",
};

export const ACTION_TYPES = {
    ...actionType(REDUCER_NAME, REDUCER_TYPE.TOGGLE_SIDEBAR, true),
    ...actionType(REDUCER_NAME, REDUCER_TYPE.SET_MAINTITLE, true),
};

const initialState = {
    open: false,
    mainTitle: 'Kezdőlap'
};


export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTION_TYPES[REDUCER_TYPE.TOGGLE_SIDEBAR]:
            return {
                ...state,
                open: "undefined" === typeof action.isOpen ? !state.open : action.isOpen
            };
        case ACTION_TYPES[REDUCER_TYPE.SET_MAINTITLE]:
            return {
                ...state,
                mainTitle: action.title
            };
        default:
            return state;
    }
}
