import React, {useEffect} from "react";
import {ValidateField} from "../../formBuilder";

export const HiddenInputDecider = (fieldObject) => {
    const {type, name} = fieldObject;
    switch (type) {
        case 'hidden':
            return (
                <React.Fragment key={name}>
                    <ValidateField {...fieldObject} component={<RenderHiddenField/>}/>
                </React.Fragment>);
        default:
            return false
    }
};


const RenderHiddenField = (props) => {
    const {name, defaultValue, setValue} = props;
    useEffect(() => {
        setValue(name, defaultValue)
    }, [defaultValue, name, setValue]);
    return (<input type={"hidden"} name={props.name} ref={props.register({})}/>)
};
