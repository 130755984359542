import React from "react";
import {FormBuilder} from "../../components";
import {EtlapFormConstants} from "../../constants/pages/etlap-constants";

const Etlap = () => {
    const handleSubmit = (values) => {
        console.debug("values", values)
    };

    const initialValues = {
        etlap: [{
            etlapKategoriaNev: "Étel Kategória1",
            etelkategoria: [
                {etelNev: "Étel1", etelLeiras: "leiras1", etelAr: "1", icons: ["fish"]},
                {etelNev: "Étel2", etelLeiras: "leiras2", etelAr: "2", icons: ["vegan", "chili"]},
                {etelNev: "Étel3", etelLeiras: "leiras3", etelAr: "3", icons: ["fish"]},
            ]
        },
            {
                etlapKategoriaNev: "Étel Kategória2",
                etelkategoria: [
                    {etelNev: "Étel11", etelLeiras: "leiras11", etelAr: "11", icons: ["chili"]},
                    {etelNev: "Étel12", etelLeiras: "leiras12", etelAr: "12", icons: ["fish", "chili"]},
                ]
            }]
    }

    return <FormBuilder {...EtlapFormConstants} onSubmit={handleSubmit} useIsEdit initialValues={initialValues}/>
}

export {Etlap};
