import React from "react";
import {useLoginPageTheme} from "../theme/pages/loginpage-theme";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import * as logo from "../theme/assets/imgs/logo.png"
import {FormBuilder} from "../components";
import {loginFormConstants} from "../constants/pages/login-constants";
import Button from "@material-ui/core/Button";
import {remoteSubmit} from "../components/formbuilder/formBuilder";
import {handleLoginAction} from "../redux/auth/action";
import {useSelector} from "react-redux";
import Alert from '@material-ui/lab/Alert';
import Grow from "@material-ui/core/Grow";

export const Copyright = () => {
    return (
        <Grid justify={"center"}>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © Pizza Max Caffe '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
            <Box mt={1}>
                <Typography variant="body2" color="textSecondary" align="center">
                    {'Készítette: Sali István '}
                </Typography>
            </Box>
        </Grid>
    );
};

const LoginPage = () => {
    const classes = useLoginPageTheme();
    const errorMessage = useSelector(state => state.auth.errorMessage);
    const handleSubmit = (values) => {
        handleLoginAction(values)
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} md={7} className={classes.image}/>
            <Grid item xs={12} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <img alt={"logo"} src={logo} height={50}/>
                    <Box mt={2}>
                        <Typography component="h1" variant={"h6"}>
                            Admin panel
                        </Typography>
                    </Box>
                    <FormBuilder {...loginFormConstants} onSubmit={handleSubmit}>
                        <Box mt={2} className={classes.submitBox}>
                            <Button
                                fullWidth
                                type={'submit'}
                                variant={"contained"}
                                color={"primary"}
                                className={classes.submit}
                                onClick={() => remoteSubmit(loginFormConstants.formName)}
                            >
                                Bejelentkezés
                            </Button>
                        </Box>
                    </FormBuilder>
                    <Grow in={Boolean(errorMessage)}>
                        <Box mt={2} className={classes.submitBox}>
                            <Alert severity="error" children={errorMessage}/>
                        </Box>
                    </Grow>
                    <Box mt={2}>
                        <Copyright/>
                    </Box>
                </div>
            </Grid>
        </Grid>
    );
};

export default LoginPage;
