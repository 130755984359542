import {useEffect} from "react";

export const useDidMount = (didMountFunction, unMountFunction) => {
    useEffect(() => {
        didMountFunction && didMountFunction();
        if (unMountFunction) {
            return () => unMountFunction()
        }
        // eslint-disable-next-line
    }, [])
}
