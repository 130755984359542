import React from "react";
import {ValidateField} from "../../formBuilder";
import FieldArrayInput from "../inputs/fieldArray";

export const FieldArrayDecider = (fieldObject) => {
    const {type, name} = fieldObject;
    switch (type) {
        case 'fieldArray':
            return (
                <React.Fragment key={name}>
                    <ValidateField {...fieldObject} component={<RenderFieldArrayInput/>}/>
                </React.Fragment>);
        default :
            return false;
    }
};


const RenderFieldArrayInput = (props) => {
    return <FieldArrayInput {...props}/>
};
