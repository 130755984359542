import {VALIDATION} from "../../components/formbuilder/validations";

export const userFormConstants = {
    formName: "usersForm",
    simpleForm: false,
    withFormMenu: true,
    content: [{
        panel: {
            title: 'Aktív felhasználók listája',
            alwaysOpen: true,
            open: false,
        },
        fields: [{
            type: 'fieldArray',
            name: 'users',
            addButtonName: 'Felhasználó hozzáadása',
            watchedField: 'username',
            fieldArrayFields: [
                {
                    type: 'hidden',
                    name: 'users[$index$].rowId'
                },
                {
                    type: "text",
                    name: "users[$index$].firstname",
                    label: "Vezetéknév",
                    size: "small",
                    margin: "dense",
                    flexWidth: 6,
                    validations: [VALIDATION.REQUIRED]
                }, {
                    type: "text",
                    name: "users[$index$].lastname",
                    label: "Keresztnév",
                    size: "small",
                    margin: "dense",
                    flexWidth: 6,
                    validations: [VALIDATION.REQUIRED]
                }, {
                    type: "text",
                    name: "users[$index$].username",
                    label: "Felhsználónév",
                    size: "small",
                    margin: "dense",
                    flexWidth: 4,
                    validations: [VALIDATION.REQUIRED]
                },
                {
                    type: "style",
                    tag: 'br',
                    name: 'users[$index$].br-1'
                }, {
                    type: "password",
                    name: "users[$index$].password",
                    label: "Új jelszó",
                    size: "small",
                    margin: "dense",
                    flexWidth: 4,
                    validations: [VALIDATION.PASSWORD_STRENGTH]
                }, {
                    type: "password",
                    name: "users[$index$].passwordConfirm",
                    label: "Új jelszó megerősítése",
                    size: "small",
                    margin: "dense",
                    flexWidth: 4,
                    validations: [VALIDATION.PASSWORD_STRENGTH, {
                        validationName: VALIDATION.NOT_SAME_PASSWORD,
                        validationValue: 'users[$index$].password'
                    }]
                }
            ]
        }]
    }]
};
