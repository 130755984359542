import makeStyles from "@material-ui/core/styles/makeStyles";
import {drawerWidth} from "../../../config/drawerbar-config";
import {COLORS} from "../../index";

export const useSideBarTheme = makeStyles((theme) => ({
    listPadding: {
        paddingLeft: "21px !important"
    },
    subListPadding: {
        paddingLeft: "21px !important"
    },
    iconAndTextColor: {
        color: COLORS.MAIN_TITLE_GOLD
    },
    isActive: {
        color: COLORS.A_MENU_COLOR,
        fontWeight: '700 !important'
    },
    tooltip: {
        backgroundColor: COLORS.A_MENU_COLOR,
        color: 'black',
        fontWeight: 'bold',
        fontSize: 14
    },
    tooltipArrow: {
        color: COLORS.A_MENU_COLOR
    },
    drawerPaper: {
        backgroundColor: COLORS.MOBILE_NAV_ADMIN,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(0),
        },
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
}));
