import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {bigButtonTheme} from "../theme";

const BigButton = ({classes, handleClick, text}) => {
    return (
        <div className={classes.bigButton} onClick={handleClick}>
            <p style={{display: 'inline-block'}}>{text}</p>
        </div>
    )
};

export default withStyles(bigButtonTheme)(BigButton)
