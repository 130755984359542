import React, {useEffect, useState} from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer/Drawer";
import {useSideBarTheme} from "../../theme/components/page/useSideBarTheme";
import {setMainTitle, toggleSidebarAction} from "../../redux/sidebar/action";
import {useSelector} from "react-redux";
import {MenuOpen} from '@material-ui/icons'
import * as logo from '../../theme/assets/imgs/logo.png'

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import {isWidthUp, withWidth} from "@material-ui/core";
import {sidebarRoutes} from "../../constants/routes-constants";
import {getIcon} from "../../functions/getIcon";
import {goPage} from "../../functions";
import classNames from 'classnames';
import _ from 'lodash';
import {KeyboardArrowRight} from '@material-ui/icons';
import {useLocation} from "react-router";
import Tooltip from "@material-ui/core/Tooltip";

const MainListItem = (props) => {
    const classes = useSideBarTheme();
    const {title, src, icon, handleRouteClick, sub, isSub} = props;
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    const open = useSelector(state => state.sidebar.open);
    useEffect(() => {
        if (location.pathname.includes(src)) {
            setIsActive(true);
            setMainTitle(title);
        } else {
            setIsActive(false)
        }
        // eslint-disable-next-line
    }, [location]);

    return (
        <>
            <Tooltip title={!open ? title : ''} placement={"top"} disableFocusListener arrow classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow
            }}>
                <ListItem button className={classNames({
                    [classes.listPadding]: !isSub,
                    [classes.subListPadding]: isSub
                })} onClick={handleRouteClick(src)}>
                    {(icon) && <ListItemIcon>
                        {React.cloneElement(icon, {
                            className: classNames(classes.iconAndTextColor, {
                                [classes.isActive]: isActive
                            })
                        })}
                    </ListItemIcon>}
                    <ListItemText primary={title} className={classNames({
                        [classes.isActive]: isActive
                    })}/>
                </ListItem>
            </Tooltip>
            {!_.isEmpty(sub) && sub.map((subObj, i) => {
                return <MainListItem key={i} isSub title={subObj.title} src={subObj.src} icon={<KeyboardArrowRight/>}
                                     handleRouteClick={handleRouteClick}/>
            })}
        </>
    )
};

export const MainListItems = (props) => {
    const {isMdUp} = props;
    const classes = useSideBarTheme();
    const createIcon = (icon) => React.createElement(icon);
    const generatedList = sidebarRoutes.map((routeObj, i) => {
        const icon = getIcon(routeObj.icon);
        const iconEl = icon ? createIcon(icon) : null;

        const handleRouteClick = (src) => () => {
            !isMdUp() && toggleSidebarAction(false)
            src && goPage(`/${src}`)
        };

        return <MainListItem key={i} title={routeObj.title} icon={iconEl} src={routeObj.src}
                             handleRouteClick={handleRouteClick}
                             sub={routeObj.sub} isSub={false}/>
    });
    return <div className={classes.iconAndTextColor}>{generatedList}</div>
};

const SideBar = (props) => {
    const classes = useSideBarTheme();
    const open = useSelector(state => state.sidebar.open);

    const isMdUp = () => {
        return isWidthUp("md", props.width)
    };

    const handleDrawerClose = () => toggleSidebarAction(false);

    return (
        <Drawer
            variant={"permanent"}
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
        >
            {
                isMdUp() ? <>
                    <div className={classes.toolbarIcon}>
                        <Grid container justify={"center"}>
                            <img src={logo} height={30} alt={"logo"}/>
                        </Grid>
                        <IconButton onClick={handleDrawerClose}>
                            <MenuOpen className={classes.iconAndTextColor}/>
                        </IconButton>
                    </div>
                    <Divider/>
                </> : <div className={classes.toolbarIcon}/>
            }
            <List><MainListItems isMdUp={isMdUp}/></List>
        </Drawer>
    )
}

export default withWidth()(SideBar);
