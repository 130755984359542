import {fadeInDown, shake} from "react-animations";

const notFoundTheme = (theme) => ({
    root:{
        minHeight: "100vh",
        minWidth: "100vw",
        backgroundImage: 'radial-gradient(circle, #313b4a, #253041, #1a2638, #0e1c2f, #021127)'
    },
    text: {
        textAlign: 'center',
        margin: 0,
        fontFamily: 'Sulphur Point',
        fontWeight: 900,
        lineHeight: '0.7em',
        fontSize: 140,
        color: "#fff",
       // opacity: '0.8',
    },
    textsub: {
        textAlign: 'center',
        marginLeft: 0,
        fontFamily: 'Sulphur Point',
        fontWeight: 400,
        fontSize: 40,
        color: "#fff",
        [theme.breakpoints.down('sm')]: {
            lineHeight: '1em',
        },
        [theme.breakpoints.up('md')]: {
            lineHeight: '0.7em',
        },
        [theme.breakpoints.up('lg')]: {
            lineHeight: '0.7em',
        },
       // opacity: '0.8'
    },

    '@keyframes fadeInDown': fadeInDown,
    fadeInDown: {
        animationName: 'fadeInDown',
        animationDuration: '1.5s',
    },

    '@keyframes shake': shake,
    shake: {
        animation: 'shake 1s 1',
        animationDelay: '3s',

    },
});

export default notFoundTheme
