import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {removeSnackbar} from "../../redux/ui/action";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";


let displayed = [];

const Toast = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(store => store.ui.toast || []);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach(({key, message, options = {}, dismissed = false}) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, preventDuplicate: true,
                action: (key) => (
                    <IconButton aria-label="Close" id={'snackClose'} onClick={() => closeSnackbar(key)}>
                        <Close fontSize="small" style={{color: '#fff'}}/>
                    </IconButton>
                ),
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Toast;
