import React, {useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {useCheckBoxInputTheme} from "../../../../theme/components/formbuilder/checkbox-theme";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";

const CheckBoxInput = (props) => {
    const {
        register,
        setValue,
        defaultValue = [],
        label,
        name,
        rules,
        disabled,
        useIsEdit,
        flexWidth,
        options = []
    } = props;
    const classes = useCheckBoxInputTheme();
    const [checkedState, setCheckedState] = useState(defaultValue);
    const forcedDefaultValue = defaultValue || [];
    const isEdit = useSelector(state => state.form.isEdit);

    const handleChange = (event) => {
        let updatedCheckedState = checkedState;

        if(checkedState.includes(event.target.value)){
            updatedCheckedState = checkedState.filter((value => value !== event.target.value))
        } else {
            updatedCheckedState.push(event.target.value)
        }
        setCheckedState(updatedCheckedState);
        setValue(name, updatedCheckedState)
    };

    return (
        <Grid item xs={12} sm={flexWidth}>
            <FormControl component="fieldset">
                <FormLabel component="legend">{label}</FormLabel>
                <FormGroup aria-label="position" row>
                    {options.map((option, index) => {
                        return (
                            <FormControlLabel
                                key={index}
                                value={option.value}
                                name={name}
                                disabled={useIsEdit ? !isEdit : disabled}
                                control={<Checkbox
                                    defaultChecked={forcedDefaultValue.includes(option.value)}
                                    inputRef={register(rules)}
                                    onChange={handleChange}
                                    classes={{
                                    root: classes.checkboxRoot
                                }}/>}
                                label={option.label}
                                labelPlacement="start"
                            />
                        )
                    })}
                </FormGroup>
            </FormControl>
        </Grid>
    )
}

export default CheckBoxInput;
