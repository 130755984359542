import {VALIDATION} from "../../components/formbuilder/validations";

export const uzletiEbedFormConstants = {
    formName: "bussinesLunch",
    simpleForm: false,
    withFormMenu: true,
    content: [{
        panel: {
            title: 'Üzleti ebéd',
            alwaysOpen: true,
            open: false,
        },
        fields: [{
            type: "fieldArray",
            name: "bussinesLunch",
            addButtonName: 'Üzleti ebéd hozzáadása',
            watchedField: "name",
            fieldArrayFields: [
                {
                    type: 'hidden',
                    name: 'rowId'
                },
                {
                    type: "text",
                    name: "bussinesLunch[$index$].name",
                    label: "Üzleti ebéd neve",
                    size: "small",
                    margin: "dense",
                    flexWidth: 3,
                    validations: [VALIDATION.REQUIRED]
                }, {
                    type: "number",
                    name: "bussinesLunch[$index$].price",
                    label: "Üzleti ebéd Ára",
                    size: "small",
                    margin: "dense",
                    flexWidth: 3,
                    endText: 'Ft',
                    validations: [VALIDATION.REQUIRED]
                }
            ]
        }
        ]
    }]
};
