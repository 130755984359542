import React, {useEffect} from "react";
import {FormBuilder} from "../../components";
import {hetiMenuFormConstants} from "../../constants/pages/hetiMenu-constants";
import {clearForm, getForm, saveForm} from "../../redux/form/action";
import {useDidMount} from "../../hooks/useDidmount";
import {useSelector} from "react-redux";
import LoadingIndicator from "../../components/LoadingIndicator";

const HetiMenu = () => {
    const formName = 'weeklyMenu';
    const initialValues = useSelector((state) => state.form[formName]);

    const handleSubmit = (values) => {
       saveForm({values, formName})
    };

    useEffect(() => {
        if (!initialValues) {
            getForm({formName})
        }
    }, [initialValues]);

    useDidMount(() => {
    }, () => {
        clearForm({formName})
    });

    return (
        <>
            {initialValues ?
                <FormBuilder initialValues={initialValues} {...hetiMenuFormConstants} onSubmit={handleSubmit}
                             useIsEdit/> : <LoadingIndicator/>}
        </>)
}

export {HetiMenu};
