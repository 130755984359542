import React, {useCallback, useMemo, useState} from "react";
import {useFieldArray, useWatch} from "react-hook-form";
import Button from "@material-ui/core/Button";
import {formFieldComposer} from "../../formBuilder";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {useFieldArrayInputTheme} from "../../../../theme/components/formbuilder/fieldArray-theme";
import {Add, Delete} from '@material-ui/icons'
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Collapse from '@material-ui/core/Collapse';
import {openRemoteModal} from "../../../../redux/ui/action";

const FieldArrayDragContainer = ({handleRemove, index, fieldArrayName, watchedField, handleOpen, mainPanel, disabled}) => {
    const classes = useFieldArrayInputTheme();
    const title = useWatch({name: `${fieldArrayName}[${index}].${watchedField}`});
    return (
        <Grid container item
              justify={"flex-start"}
              alignItems={"center"}
              className={classNames(classes.dragContainer, {
                  [classes.mainPanel]: mainPanel,
              })} onClick={handleOpen}>
            {title || "(Ennek a panelnak meg nincs neve!)"}
            {!disabled && <Icon className={classNames(classes.delIcon, {
                [classes.mainPaneldelIcon]: mainPanel,
            })} onClick={(e) => handleRemove(index, title, e)}>
                <Delete/>
            </Icon>}
        </Grid>
    )
};

const FieldArrayRecord = ({allFields, handleRemove, fieldArrayName, watchedField, fieldArrayFieldsWitdValue, index, useIsEdit, mainPanel, disabled}) => {
    const classes = useFieldArrayInputTheme();
    const isLast = allFields.length === index + 1;
    const [open, setOpen] = useState(mainPanel ? false : isLast);

    const handleOpen = useCallback(() => {
        setOpen(!open)
    }, [open, setOpen]);


    return (
        <Grid item xs={12}>
            <FieldArrayDragContainer handleRemove={handleRemove}
                                     index={index}
                                     disabled={disabled}
                                     mainPanel={mainPanel}
                                     handleOpen={handleOpen}
                                     fieldArrayName={fieldArrayName}
                                     watchedField={watchedField}/>
            <Collapse in={open} disableStrictModeCompat>
                <Paper className={classes.fieldArrayPanel}>
                    <Grid container spacing={2}>
                        {useMemo(() => {
                            return (
                                fieldArrayFieldsWitdValue.map((field) => formFieldComposer({
                                    field,
                                    useIsEdit,
                                    fieldArrayDetails: {fieldArrayName, fieldArrayIndex: index}
                                }))
                            )
                        }, [fieldArrayFieldsWitdValue, fieldArrayName, index, useIsEdit])}
                    </Grid>
                </Paper>
            </Collapse>
        </Grid>
    )
}

const FieldsContainer = ({fields, fieldArrayName, watchedField, fieldArrayFields, handleRemove, useIsEdit, mainPanel, disabled}) => {
    return <Grid container item xs={12}>
        {
            fields.map((field, index) => {
                const fieldArrayFieldsWitdValue = Object.entries(field).map(([key, value]) => {
                    const findedField = fieldArrayFields.find((fieldArrayField) => fieldArrayField.name.endsWith(key)) || null;
                    let returnField = null;
                    if (findedField) {
                        returnField = {...findedField, defaultValue: value, name: key}
                    }
                    return returnField

                }).filter((obj) => obj !== null);
                return (
                    <FieldArrayRecord fieldArrayFieldsWitdValue={fieldArrayFieldsWitdValue}
                                      fieldArrayName={fieldArrayName}
                                      handleRemove={handleRemove}
                                      watchedField={watchedField}
                                      index={index}
                                      allFields={fields}
                                      disabled={disabled}
                                      useIsEdit={useIsEdit}
                                      mainPanel={mainPanel}
                                      key={index}/>
                )
            })
        }
    </Grid>
};

const FieldArrayInput = (props) => {
    const {name = "", fieldArrayFields = [], watchedField, addButtonName = 'Hozzáadás', useIsEdit, mainPanel} = props;
    const classes = useFieldArrayInputTheme();
    const {fields, append, remove} = useFieldArray({name});
    const isEdit = useSelector(state => state.form.isEdit);

    const handleAppend = () => {
        append(fieldArrayFields.reduce((acc, currentField, i) => {
            const splitedFildName = currentField.name.split(".");
            const fieldName = splitedFildName[splitedFildName.length - 1];
            return ({...acc, [fieldName]: null});
        }, {}))
    };

    const handleRemove = (index, title, e) => {
        e.stopPropagation();
        openRemoteModal({
            remoteModalOpen: true,
            style: "delete",
            title: `${title || "---"} törlése`,
            content: `Ön most arra készül, hogy kitörli a ${title} elemet. Ha nem szeretné kitörölni akkor kérem kattintsom a "Mégse" gombra. Ellenkező esetben erősítse meg törlési szándékat a "Törlés" gombbal!`,
            okButton: {
                title: 'Törlés',
                fn: () => remove(index)
            }
        })
    };

    return (
        <Grid item xs={12} className={classes.root}>
            <Box mb={5}>
                <Button variant={"contained"} startIcon={<Add/>} size={"small"} onClick={handleAppend}
                        disabled={!isEdit}
                        classes={{
                            contained: classNames({
                                [classes.addButton]: true,
                            }),
                        }}>
                    {addButtonName}
                </Button>
            </Box>
            <Grid container>
                <FieldsContainer fields={fields} watchedField={watchedField} fieldArrayName={name}
                                 useIsEdit={useIsEdit}
                                 disabled={!isEdit}
                                 mainPanel={mainPanel}
                                 fieldArrayFields={fieldArrayFields}
                                 handleRemove={handleRemove}/>
            </Grid>
        </Grid>
    )
};

export default FieldArrayInput;
