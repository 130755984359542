import {call, put, takeLatest} from "redux-saga/effects";
import {ACTION_TYPES, REDUCER_TYPE} from "./reducer";
import axios from "axios";

const BASE_URL = "/api";

const postCall = (url, data) => axios.post(url, data).then(response => response);
const getCall = (url, data) => axios.get(url, data).then(response => response);

export function* saveForm({data}) {
    const {formName, ...restData} = data;
    try {
        const payload = yield call(postCall, `${BASE_URL}/${formName}/save`, {...restData});
        yield put({type: ACTION_TYPES[REDUCER_TYPE.SAVE_FORM].SUCCESS, payload: {...payload, formName}});
    } catch (error) {
        yield put({type: ACTION_TYPES[REDUCER_TYPE.SAVE_FORM].FAILED, error});
    }
}

export function* getForm({data}) {
    const {formName, ...restData} = data;
    try {
        const payload = yield call(getCall, `${BASE_URL}/${formName}`, {...restData});
        yield put({type: ACTION_TYPES[REDUCER_TYPE.GET_FORM].SUCCESS, payload: {...payload, formName}});
    } catch (error) {
        yield put({type: ACTION_TYPES[REDUCER_TYPE.GET_FORM].FAILED, error});
    }
}


export function* formSaga() {
    yield takeLatest(ACTION_TYPES[REDUCER_TYPE.GET_FORM].FETCH, getForm);
    yield takeLatest(ACTION_TYPES[REDUCER_TYPE.SAVE_FORM].FETCH, saveForm);
}
