import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {useLoadingIndicatorTheme} from "./style";

const LoadingIndicator = () => {
    const classes = useLoadingIndicatorTheme();
    return (
        <Grid container justify={"center"} alignItems={"center"} className={classes.root}>
            <CircularProgress color="secondary"/>
        </Grid>
    )
};

export default LoadingIndicator;
