import React, {useEffect} from "react";
import {goPage} from "../../functions";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import notFoundTheme from "../../theme/pages/error/notfound-theme";
import BigButton from "../../components/bigButton";
import classNames from 'classnames';


const NotFound = ({setNotFound = () => {}, error = false,  classes}) => {
    useEffect(() => {
        setNotFound(true);
        return () => setNotFound(false)
    }, [setNotFound]);

    const forceGoToLocation = () => {
        window.location.replace("/");
    };

    return <Grid className={classes.root} container justify={"center"} alignItems={"center"} direction={"column"}>
        <Grid item>
            <p className={classNames(classes.text, classes.fadeInDown)}>{error ? "HIBA!" : "404"}</p>
            <p className={classes.textsub}>{error ? "Próbád meg frissíteni az oldalt!" : "Ez az oldal nem található! :("}</p>
        </Grid>
        <Grid item className={classes.shake} onClick={() => error ? forceGoToLocation() : goPage('/')}>
            <BigButton text={"Vissza a főoldalra"}/>
        </Grid>
    </Grid>
};

export default withStyles(notFoundTheme, {withTheme: true})(NotFound)
